/**
 * @name: common
 * @author: itmobai
 * @date: 2022-11-08 14:30
 * @description：common
 * @update: 2022-11-08 14:30
 */

/**
 * 解析拿到code
 * @param url 地址
 * @param key
 */
const dealParseQrcode = (url: string, key = 'qrcode') => {
  if (!url) {
    return ''
  }
  let index = url.indexOf('?')
  if (index == -1) {
    return ''
  }
  url = url.substring(index + 1)
  let arr = url.split('&')
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].indexOf(key) != -1) {
      return arr[i].split('=')[1]
    }
  }
  return ''
}

export default {
  dealParseQrcode
}
